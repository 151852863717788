import React, { useState, useEffect } from 'react';
// import axios from 'axios';
import Navbar from '../../components/navbar/navbar';
import Sidebar from '../../components/sidebar/sidebar';
import './AdminSettings.css';

const AdminSettings = () => {
  const [roles, setRoles] = useState([
    'Admin',
    'Manager',
    'Supervisor',
    'Employee'
  ]);
  const [selectedRole, setSelectedRole] = useState('');
  const [controls, setControls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchControls = async () => {
    if (!selectedRole) return;
    
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(
        'https://theexperts-admin-apim.azure-api.net/admin-module/internal/v1/control/create',
        {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json',
              'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_SUBSCRIPTION_KEY
          }
        }
      );
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch controls');
      }
      const data = await response.json();
      setControls(data);
    } catch (err) {
      setError('Failed to fetch controls. Please try again.');
      console.error('Error fetching controls:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchControls();
  }, [selectedRole]);

  return (
    <div className="container">
      <div className="inner-container">
        <Navbar title="Admin Settings" />
        <div style={{ display: 'flex' }}>
          <Sidebar />
          <div className="settings-container">
            <div className="role-selector">
              <h2>Role Management</h2>
              <select 
                value={selectedRole} 
                onChange={(e) => setSelectedRole(e.target.value)}
                className="role-dropdown"
              >
                <option value="">Select Role</option>
                {roles.map((role) => (
                  <option key={role} value={role}>{role}</option>
                ))}
              </select>
            </div>

            {loading && <div className="loading">Loading controls...</div>}
            {error && <div className="error-message">{error}</div>}
            
            {!loading && !error && selectedRole && (
              <div className="controls-container">
                <h3>Access Controls for {selectedRole}</h3>
                <table className="controls-table">
                  <thead>
                    <tr>
                      <th>Feature</th>
                      <th>Read</th>
                      <th>Write</th>
                    </tr>
                  </thead>
                  <tbody>
                    {controls.map((control) => (
                      <tr key={control.id}>
                        <td>{control.feature}</td>
                        <td>
                          <input
                            type="checkbox"
                            checked={control.read}
                            onChange={() => {/* Handle read permission change */}}
                          />
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={control.write}
                            onChange={() => {/* Handle write permission change */}}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminSettings;