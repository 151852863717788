import React, { useState, useEffect, useContext } from 'react';
import Navbar from "../../components/navbar/navbar";
import { Link, useNavigate } from "react-router-dom";
import './dashboard.css'
import Graph from "../../components/graph/graph";
import Sidebar from "../../components/sidebar/sidebar";
import { verifyUser } from '../../utils/auth';
import { useAuth } from '../../context/AuthContext';

// Add this helper function
const formatUsername = (username) => {
    if (!username) return '';
    return username.split(/[\s_-]/)
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

export default function Dashboard(){
    const navigate = useNavigate();
    const { user, setIsAuthenticated, logout, setUser } = useAuth();
    const date = new Date()
    const [stats, setStats] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('All Category');
    const [selectedYear, setSelectedYear] = useState(date.getFullYear().toString());
    const [selectedMonth, setSelectedMonth] = useState(date.toLocaleString('default', { month: 'short' }));
    const [spStats, setSpStats] = useState([]);

    // Check authentication on mount and maintain session
    useEffect(() => {
        const initializeAuth = () => {
            // First check localStorage for persistent auth
            const token = localStorage.getItem('authToken');
            const userData = localStorage.getItem('userData');

            if (!token) {
                // console.log('No token found in storage');
                navigate('/login');
                return;
            }

            // Sync sessionStorage with localStorage
            sessionStorage.setItem('authToken', token);
            if (userData) {
                sessionStorage.setItem('userData', userData);
            }

            // Set authentication state
            setIsAuthenticated(true);
            if (userData) {
                try {
                    const parsedUserData = JSON.parse(userData);
                    setUser(parsedUserData);
                } catch (e) {
                    // console.error('Error parsing user data:', e);
                }
            }

            // Optional background token verification
            const verifyTokenSilently = async () => {
                try {
                    const isValid = await verifyUser();
                    if (!isValid) {
                        // console.warn('Token verification failed, but keeping session active');
                    }
                } catch (error) {
                    // console.error('Token verification error:', error);
                    // Don't logout on verification error
                }
            };

            verifyTokenSilently();
        };

        initializeAuth();
    }, [navigate, setIsAuthenticated, setUser]);

    // Fetch data when authenticated
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                if (!token) {
                    // console.error('No auth token found for data fetching');
                    return;
                }

                await Promise.all([fetchStats(), fetchSpStats()]);
            } catch (error) {
                // console.error('Data fetching error:', error);
                setError('Failed to fetch data');
            }
        };

        fetchData();
    }, [selectedCategory, selectedYear, selectedMonth]);

    const fetchStats = async () => {
        try {
            setIsLoading(true);
            
            // First verify to get latest JWT
            const newJwt = await verifyUser();
            if (!newJwt) {
                throw new Error('Failed to get valid JWT token');
            }

            // Now fetch stats using the new JWT token
            const response = await fetch('https://theexperts-admin-apim.azure-api.net/admin-module/internal/v1/analytics/stats', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': newJwt,
                    'X-user-key': 'master'
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to fetch stats');
            }

            const data = await response.json();
            // console.log('Stats response:', data);

            if (data.success) {
                const statsModel = data.statsModel || {};
                setStats({
                    todayJobs: parseInt(statsModel.todayJobs) || 0,
                    closedJobs: parseInt(statsModel.closedJobs) || 0,
                    canceledJobs: parseInt(statsModel.canceledJobs) || 0,
                    escalationJobs: parseInt(statsModel.escalationJobs) || 0,
                    totalRevenue: parseFloat(statsModel.totalRevenue) || 0,
                    totalOutstanding: parseFloat(statsModel.totalOutstanding) || 0,
                    avgTicketSize: parseFloat(statsModel.avgTicketSize) || 0
                });
            } else {
                throw new Error(data.message || 'Failed to fetch stats');
            }
        } catch (error) {
            // console.error('Error fetching stats:', error);
            setError(error.message || 'Failed to fetch stats');
            // If there's an auth error, redirect to login
            if (error.message.includes('auth token') || error.message.includes('JWT')) {
                navigate('/login');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSpStats = async () => {
        try {
            const verifyResponse = await fetch('https://theexperts-admin-apim.azure-api.net/login-module/internal/v1/login/verify', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': localStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify({
                    "id": "",
                    "userName": "master",
                    "password": "C5v8=l-8<<?a",
                    "phoneNumber": "",
                    "email": "",
                    "otp": ""
                })
            });

            if (!verifyResponse.ok) {
                throw new Error('Failed to verify user');
            }

            const verifyData = await verifyResponse.json();
            const newJwt = verifyData.jwt;

            const queryParams = new URLSearchParams({
                category: selectedCategory === 'All Category' ? '' : selectedCategory,
                year: selectedYear || '',
                month: selectedMonth || ''
            }).toString();

            // Check if the endpoint exists before making the request
            try {
                const spStatsResponse = await fetch(
                    `https://theexperts-admin-apim.azure-api.net/admin-module/internal/v1/analytics/sp-stats?${queryParams}`,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'X-session-token': newJwt,
                            'X-user-key': 'master'
                        }
                    }
                );

                if (spStatsResponse.status === 404) {
                    // console.log('SP stats endpoint not available');
                    setSpStats([]); // Set empty array if endpoint doesn't exist
                    return;
                }

                if (!spStatsResponse.ok) {
                    throw new Error(`HTTP error! status: ${spStatsResponse.status}`);
                }

                const data = await spStatsResponse.json();
                if (data.success && Array.isArray(data.spStats)) {
                    setSpStats(data.spStats);
                } else {
                    setSpStats([]);
                }
            } catch (error) {
                // console.log('SP stats endpoint not available:', error);
                setSpStats([]); // Set empty array on error
            }
        } catch (error) {
            // console.error('Error fetching SP stats:', error);
            setSpStats([]); // Set empty array on error
        }
    };

    const createControl = async (displayName) => {
        try {
            const response = await fetch(`https://theexperts-admin-apim.azure-api.net/admin-module/internal/v1/control/create`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-session-token': localStorage.getItem('authToken'),
                    'X-user-key': 'master'
                },
                body: JSON.stringify({ displayName })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            return data;
        } catch (err) {
            // console.error('Error creating control:', err);
            throw err;
        }
    };

    // Add this function to generate cards dynamically
    const getCardsList = () => {
        if (!stats) return [];
        
        return [
            {
                title: "Today's Job",
                value: stats.todayJobs,
                variant: "#4C51BF",
                href: "/job/today",
                icon: "📊"
            },
            {
                title: "Closed Job",
                value: stats.closedJobs,
                variant: "#48BB78",
                href: "/job/closed",
                icon: "✔️"
            },
            {
                title: "Canceled Job",
                value: stats.canceledJobs,
                variant: "#718096",
                href: "/job/cancelled",
                icon: "❌"
            },
            {
                title: "Escalation Job",
                value: stats.escalationJobs,
                variant: "#E53E3E",
                href: "/job/escalated",
                icon: "⚠️"
            },
            {
                title: "Total Revenue",
                value: `₹ ${stats.totalRevenue.toFixed(2)}`,
                variant: "#2C7A7B",
                href: "/metrics/revenue",
                icon: "💰"
            },
            {
                title: "Total Outstanding",
                value: `₹ ${stats.totalOutstanding.toFixed(2)}`,
                variant: "#805AD5",
                href: "/metrics/outstanding",
                icon: "💳"
            },
            {
                title: "Avg. Ticket Size",
                value: `₹ ${stats.avgTicketSize.toFixed(2)}`,
                variant: "#319795",
                href: "/metrics/ticket-size",
                icon: "🎫"
            },
            {
                title: "Total Jobs",
                value: stats.totalJobs,
                variant: "#667EEA",
                href: "/jobs",
                icon: "👥"
            }
        ];
    };

    // Update the filter handlers
    const handleCategoryChange = (e) => {
        setSelectedCategory(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
        // Clear month when year changes
        setSelectedMonth('');
    };

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value);
    };

    return(
        <div className="container">
            <Sidebar/>
            <div className="inner-container">
            <Navbar title={"Dashboard"}/>
            <div className="dashboard-outer-container">
                <h2 className="welcome-text">
                    Hi {formatUsername(user?.userName) || 'Master'}, Welcome to The Experts
                </h2>
                {error && <div className="error-message">{error}</div>}
                {isLoading ? (
                    <div className="loading">Loading statistics...</div>
                ) : (
                    <>
                        <div className="dashboard-container">
                        <div className="stats-header">
                            <div className="stats-title">
                            <span className="stats-icon">📊</span>
                            <span>Common statistics</span>
                            </div>
                            
                            <div className="stats-filters">
                            <select className="filter-select" defaultValue={"All Category"}>
                                <option>All Category</option>
                                <option>Salon At Home</option>
                                <option>Make Up</option>
                            </select>
                            <select 
                                className="filter-select" 
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                <option value="">All Years</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                            </select>
                            <select 
                                className="filter-select" 
                                value={selectedMonth}
                                onChange={handleMonthChange}
                            >
                                <option value="">All Months</option>
                                <option value="Jan">January</option>
                                <option value="Feb">February</option>
                                <option value="Mar">March</option>
                                <option value="Apr">April</option>
                                <option value="May">May</option>
                                <option value="Jun">June</option>
                                <option value="Jul">July</option>
                                <option value="Aug">August</option>
                                <option value="Sep">September</option>
                                <option value="Oct">October</option>
                                <option value="Nov">November</option>
                                <option value="Dec">December</option>
                            </select>
                            <button 
                                className="go-button"
                                onClick={() => {
                                    fetchStats();
                                    fetchSpStats();
                                }}
                            >
                                Go
                            </button>
                            </div>
                        </div>
                        
                        <div className="stats-grid">
                            {getCardsList().map((card, index) => (
                                <Link to={card.href} key={index} className="stats-card" style={{backgroundColor: card.variant}}>
                                    <div className="card-info">
                                        <span className="card-label">{card.title}</span>
                                        <span className="card-value">{card.value}</span>
                                    </div>
                                    <div className="card-icon">{card.icon}</div>
                                </Link>
                            ))}
                        </div>
                        </div>
                        <div className="dashboard-inner-container">
                        <div className="dashboard-container dashboard-container-2">
                        <div className="stats-header">
                            <div className="stats-title">
                            <span className="stats-icon">📊</span>
                            <span>Analytics</span>
                            </div>
                            
                            <div className="stats-filters">
                            <select 
                                className="filter-select" 
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                            >
                                <option value="All Category">All Category</option>
                                <option value="Salon At Home">Salon At Home</option>
                                <option value="Make Up">Make Up</option>
                            </select>
                            <select 
                                className="filter-select" 
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                <option value="">All Years</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                            </select>
                            <select 
                                className="filter-select" 
                                value={selectedMonth}
                                onChange={handleMonthChange}
                            >
                                <option value="">All Months</option>
                                <option value="Jan">January</option>
                                <option value="Feb">February</option>
                                <option value="Mar">March</option>
                                <option value="Apr">April</option>
                                <option value="May">May</option>
                                <option value="Jun">June</option>
                                <option value="Jul">July</option>
                                <option value="Aug">August</option>
                                <option value="Sep">September</option>
                                <option value="Oct">October</option>
                                <option value="Nov">November</option>
                                <option value="Dec">December</option>
                            </select>
                            <button 
                                className="go-button"
                                onClick={() => {
                                    fetchStats();
                                    fetchSpStats();
                                }}
                            >
                                Go
                            </button>
                            </div>
                        </div>
                        
                        <div className="graph-container"><Graph/></div>
                        </div>
                        <div className="dashboard-container dashboard-container-2">
                        <div className="stats-header">
                            <div className="stats-title">
                            <span className="stats-icon">📊</span>
                            <span>SP statistics</span>
                            </div>
                            
                            <div className="stats-filters">
                            <select 
                                className="filter-select" 
                                value={selectedCategory}
                                onChange={handleCategoryChange}
                            >
                                <option value="All Category">All Category</option>
                                <option value="Salon At Home">Salon At Home</option>
                                <option value="Make Up">Make Up</option>
                            </select>
                            <select 
                                className="filter-select" 
                                value={selectedYear}
                                onChange={handleYearChange}
                            >
                                <option value="">All Years</option>
                                <option value="2024">2024</option>
                                <option value="2023">2023</option>
                            </select>
                            <select 
                                className="filter-select" 
                                value={selectedMonth}
                                onChange={handleMonthChange}
                            >
                                <option value="">All Months</option>
                                <option value="Jan">January</option>
                                <option value="Feb">February</option>
                                <option value="Mar">March</option>
                                <option value="Apr">April</option>
                                <option value="May">May</option>
                                <option value="Jun">June</option>
                                <option value="Jul">July</option>
                                <option value="Aug">August</option>
                                <option value="Sep">September</option>
                                <option value="Oct">October</option>
                                <option value="Nov">November</option>
                                <option value="Dec">December</option>
                            </select>
                            <button 
                                className="go-button"
                                onClick={() => {
                                    fetchStats();
                                    fetchSpStats();
                                }}
                            >
                                Go
                            </button>
                            </div>
                        </div>
                        <div className="table-container">
                            <table>
                                <thead>
                                    <tr>
                                        <th>SP Name</th>
                                        <th>Jobs</th>
                                        <th>Revenue</th>
                                        <th>Escalation</th>
                                        <th>Rating</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {spStats.map((sp, index) => (
                                        <tr key={index}>
                                            <td>{sp.name}</td>
                                            <td>{sp.jobs}</td>
                                            <td>{sp.revenue ? `₹${sp.revenue}` : ''}</td>
                                            <td>{sp.escalations}</td>
                                            <td>{sp.rating}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        </div>
                        </div>
                    </>
                )}
            </div>
            </div>
        </div>
    )
}