import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import Login from './pages/login/login';
import Dashboard from './pages/dashboard/dashboard';
import AllJobs from './pages/jobs/AllJobs';
import HeroBannerSlide from './pages/herobanner/herobanner';
import OfferSlide from './pages/offerslider/offerslider';
import PromotionalBannerUpload from './pages/promotional/promotionalBanner';
import PackageSelector from './pages/packageSelector/packageSelector';
import ServicePackages from './pages/servicePackage/servicepackage';
import JobDashboard from './pages/todaysJobs/jobDasboard';
import Upcomingjobs from './pages/upcomingjobs/upcomingjobs';
import CancelledJobs from './pages/cancelledJobs/cancelledjobs';
import CreateJob from './pages/createJob/createJob';
import MasterCategoryList from './pages/masterCategory/masterCategory';
import CategoryList from './pages/category/Category';
import SubCategoryList from './pages/subcategory/subcategoryList';
import ServiceListing from './pages/services/ServiceListing';
import Packages from './pages/packageList/packageList';
import ServiceProvider from './pages/serviceProvider/serviceProvider';
import CustomerManagement from './pages/customer/customer';
import SlotManager from './pages/slotmanager/slotmanager';
import Coupons from './pages/coupons/coupons';
import HubMaster from './pages/hubMaster/hubMaster';
import ContactsTable from './pages/contact/Contact';
import ExpertsRequest from './pages/expertsRequest/expertsrequest';
import BlogListing from './pages/blogListing/blogListing';
import Profile from './pages/profile/profile';
import TomorrowOffers from './pages/tomorrowOffers/tomorrowOffers';
import PackageTable from './pages/productInventory/productInventory';
import Controls from './pages/controls/Controls';
import Users from './pages/users/Users';
import Sidebar from './components/sidebar/sidebar';
import AdminSettings from './pages/adminSettings/AdminSettings';
// import AppSettings from './pages/appSettings/AppSettings';

function App() {
  // Protected Route component with persistent auth check
  const ProtectedRoute = ({ children }) => {
    const token = sessionStorage.getItem('authToken') || localStorage.getItem('authToken');
    if (!token) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route 
            path="/login" 
            element={
              sessionStorage.getItem('authToken') || localStorage.getItem('authToken') ? 
              <Navigate to="/dashboard" replace /> : 
              <Login />
            } 
          />
          <Route
            path="/dashboard"
            element={<Dashboard />}
          />
          <Route
            path="/jobs/*"
            element={
              <ProtectedRoute>
                <AllJobs />
              </ProtectedRoute>
            }
          />
          <Route path="/hero-banner-slider" element={
            <ProtectedRoute>
              <HeroBannerSlide />
            </ProtectedRoute>
          } />
          <Route path="/global-setting/admin" element={
            <ProtectedRoute>
              <AdminSettings />
            </ProtectedRoute>
          } />
          {/* <Route path="/global-setting/app" element={
            <ProtectedRoute>
              <AdminSettings />
            </ProtectedRoute>
          } /> */}
          <Route path="/offer-banners" element={<OfferSlide />} />
          <Route path="/promotional-banners" element={<PromotionalBannerUpload />} />
          <Route path="/most-recommended-services" element={<PackageSelector />} />
          <Route path="/tomorrows-offers" element={<TomorrowOffers />} />
          <Route path="/add-budget" element={<ServicePackages />} />
          <Route path="/job/today" element={
            <ProtectedRoute>
              <JobDashboard />
            </ProtectedRoute>
          } />
          <Route path="/job/upcoming" element={
            <ProtectedRoute>
              <Upcomingjobs />
            </ProtectedRoute>
          } />
          <Route path="/job/cancelled" element={
            <ProtectedRoute>
              <CancelledJobs />
            </ProtectedRoute>
          } />
          <Route path="/job/create" element={
            <ProtectedRoute>
              <CreateJob />
            </ProtectedRoute>
          } />
          <Route path="/master-category" element={
            <ProtectedRoute>
              <MasterCategoryList />
            </ProtectedRoute>
          } />
          <Route path="/category" element={
            <ProtectedRoute>
              <CategoryList />
            </ProtectedRoute>
          } />
          <Route path="/sub-category" element={
            <ProtectedRoute>
              <SubCategoryList />
            </ProtectedRoute>
          } />
          <Route path="/services" element={
            <ProtectedRoute>
              <ServiceListing />
            </ProtectedRoute>
          } />
          <Route path="/packages" element={
            <ProtectedRoute>
              <Packages />
            </ProtectedRoute>
          } />
          <Route path="/service-providers" element={
            <ProtectedRoute>
              <ServiceProvider />
            </ProtectedRoute>
          } />
          <Route path="/customers" element={
            <ProtectedRoute>
              <CustomerManagement />
            </ProtectedRoute>
          } />
          <Route path="/slot-master" element={
            <ProtectedRoute>
              <SlotManager />
            </ProtectedRoute>
          } />
          <Route path="/coupons" element={
            <ProtectedRoute>
              <Coupons />
            </ProtectedRoute>
          } />
          <Route path="/hub-master" element={
            <ProtectedRoute>
              <HubMaster />
            </ProtectedRoute>
          } />
          <Route path="/blog-master" element={
            <ProtectedRoute>
              <BlogListing />
            </ProtectedRoute>
          } />
          <Route path="/contacts" element={
            <ProtectedRoute>
              <ContactsTable />
            </ProtectedRoute>
          } />
          <Route path="/experts-request" element={
            <ProtectedRoute>
              <ExpertsRequest />
            </ProtectedRoute>
          } />
          <Route path="/profile" element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          } />
          <Route path="/product-inventory" element={
            <ProtectedRoute>
              <PackageTable />
            </ProtectedRoute>
          } />
          <Route path="/controls" element={
            <ProtectedRoute>
              <Controls />
            </ProtectedRoute>
          } />
          <Route path="/users" element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          } />
          <Route path="/" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;